@use '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.prices-block {
    width: 100%;
    height: 820px;
    padding-bottom: 175px;
    background-color: $white;
    @include mixins.flex(row, center, center);

    @media (max-width: 1300px) {
        height: auto;
        padding-bottom: 138px;
    }

    @media (max-width: 1100px) {
        padding-bottom: 128px;
    }

    .prices-block-container {
        width: calc(100% - 80px);
        height: 100%;
        max-width: 1360px;
        @include mixins.flex(column, flex-start, center);

        @media (max-width: 1200px) {
            width: calc(100% - 32px);
        }

        h2 {
            width: 60%;
            margin: 0 0 48px 0;
            text-transform: uppercase;
            text-align: center;
            @include mixins.font(Inter, 80px, normal, 400, 90%);

            @media (max-width: 1000px) {
                width: 70%;
                @include mixins.font(Inter, 55px, normal, 400, 90%);
            }

            @media (max-width: 600px) {
                width: 100%;
                max-width: 343px;
                margin: 0 0 40px 0;
                @include mixins.font(Inter, 36px, normal, 400, 90%);
            }
        }

        .prices-block-info-box {
            width: 100%;
            gap: 20px;
            @include mixins.flex(row, space-between, center);

            @media (max-width: 1300px) {
                @include mixins.flex(column, flex-start, center);
            }
        }
    }

}